import React from "react";
import "./header.css";
import sideImage from "../../Assets/about-img.png";
import Video from "../../Assets/Banner Video.mp4";
import Navbar from "../navbar/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import Whatspp from "../../Assets/whatsapp-logo.png";

import AnimatedText from "react-animated-text-content";
const index = () => {
  return (
    <div>
      <body className="rlr-body">
        <div class="icon-container">
          <a href="https://wa.me/+923323549993 ">
            <img src={Whatspp} className="fixed_whatsapp" alt="whatsapp-icon" />
          </a>
        </div>
        <aside className="rlr-hero--half-mast">
          <div className="rlr-banner__media">
            <header>
              <div className="outter">
                <div className="video-container">
                  <video src={Video} autoPlay loop playsInline muted />

                  <div className="container-fluid px-4 rlr-callout">
                    <Navbar />

                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12  heading ">
                          <AnimatedText
                            type="words" // animate words or chars
                            animation={{
                              x: "200px",
                              y: "-20px",
                              scale: 1.1,
                              ease: "ease-in-out",
                            }}
                            animationType="throw"
                            interval={0.4}
                            duration={1.5}
                            tag="h1"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={1.0}
                            rootMargin="20%"
                            style={{ fontSize: "45px" }}
                          >
                            Let's Take
                          </AnimatedText>

                          <AnimatedText
                            type="words" // animate words or chars
                            animation={{
                              x: "200px",
                              y: "-20px",
                              scale: 1.1,
                              ease: "ease-in-out",
                            }}
                            animationType="throw"
                            interval={1.5}
                            duration={1.5}
                            tag="h1"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={0.1}
                            rootMargin="20%"
                          >
                            TECHNOLOGY
                          </AnimatedText>

                          <AnimatedText
                            type="words" // animate words or chars
                            animation={{
                              x: "200px",
                              y: "-20px",
                              scale: 1.1,
                              ease: "ease-in-out",
                            }}
                            animationType="throw"
                            interval={0.4}
                            duration={1.5}
                            tag="h1"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={1.0}
                            rootMargin="20%"
                            style={{ fontSize: "45px" }}
                          >
                            To a New
                          </AnimatedText>

                          <AnimatedText
                            type="words" // animate words or chars
                            animation={{
                              x: "200px",
                              y: "-20px",
                              scale: 1.1,
                              ease: "ease-in-out",
                            }}
                            animationType="throw"
                            interval={1.5}
                            duration={1.5}
                            tag="h1"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={0.1}
                            rootMargin="20%"
                            style={{
                              color: "#f67510",
                              fontWeight: "500",
                              letterSpacing: "10px",
                            }}
                          >
                            DIMENSION
                          </AnimatedText>
                          <br></br>

                          {/* <button>DISCUSS PROJECT</button> */}
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 astronaut">
                          <div class="right-sections">
                            <img src={sideImage} alt="astronaut" />
                          </div>
                          {/* <img
                            src={sideImage}
                            width="100%"
                            height="100%"
                            alt=""
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </aside>
      </body>
    </div>
  );
};

export default index;
