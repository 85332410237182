import React from "react";
import "./workHeader.css";
// import WEB from '../../../Assets/WEB.png';
import Navbar from "../../../component/navbar2/index";
// import BackgroundImg from '../../../Assets/Group 136.png';
import AnimatedText from "react-animated-text-content";
import Banner from "../../../Assets/space-background-with-fictional-planets.jpg";

const index = () => {
  return (
    <div id="workHeader">
      <div className="service-media">
        <header>
          <div className="outter">
            <div className="video-container work-video">
              {/* <div className="work-headerimg">
                <img src={BackgroundImg} alt="" />
              </div> */}
              <img className="work-banner" src={Banner} alt="" />
              {/* <video src={Video} autoPlay loop playsInline muted /> */}

              <div className="container-fluid px-4 rlr-callout">
                <Navbar />

                <div className="container" style={{ position: "relative" }}>
                  <div className="row">
                    <div className="col-12 work-heading">
                      <AnimatedText
                        type="chars" // animate words or chars
                        animation={{
                          x: "200px",
                          y: "-20px",
                          scale: 1.1,
                          ease: "ease-in-out",
                        }}
                        animationType="bounce"
                        interval={0.1}
                        duration={0.8}
                        tag="h1"
                        className="animated-paragraph text-center contact-heading"
                        includeWhiteSpaces
                        threshold={1.0}
                        rootMargin="20%"
                      >
                        WORKS
                      </AnimatedText>
                      <div>
                        <p className="worker-header-subtext">
                          Transforming ideas into high-quality software products
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default index;
