import React from "react";
import "./footer.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import { Link } from "react-router-dom";
// import Telephone from "../../Assets/telephone.png";
// import Location from "../../Assets/location.png";
import Logo from "../../Assets/logo 3.png";
// import Phone from "../../Assets/phone.png";
// import Facebook from "../../Assets/facebook.png";
// import Google from "../../Assets/google.png";
// import Twitter from "../../Assets/twitter.png";
// import Pintrest from "../../Assets/pintrst.png";
import { Lin2 } from "../extraInfo/index";

const index = () => {
  return (
    <div className="bg-img mt-5">
      <Lin2 />

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-4 col-sm-12 footer-logo ">
            <img src={Logo} width="50%" alt="" />

            <table className="mt-4">
              <br></br>
              <tr className="pt-2">
                <td>
                  {/* <i class="bi bi-whatsapp"></i> */}
                  <i class="bi bi-envelope"></i>
                  {/* <img src={Location} width="20px" alt="img-icon" /> */}
                </td>
                <td className="px-2">
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="mailto:info@xcltechnologies.com"
                  >
                    info@xcltechnologies.com
                  </a>
                </td>
              </tr>
              <br></br>
              <tr>
                <td>
                  <i class="bi bi-whatsapp"></i>

                  {/* <img src={Phone} width="20px" alt="img-icon" /> */}
                </td>
                <td className="px-2">
                  <a
                    href="https://wa.me/+923322007888"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    +92 332 2007888
                  </a>
                </td>
              </tr>
            </table>

            <div className=" d-flex mt-4 footer-icons">
              <div className="p-2">
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="https://www.facebook.com/XclTechnologies"
                >
                  <i class="bi bi-facebook"></i>
                </a>
                {/* <img src={Facebook} width="15px" alt="img-icon" /> */}
              </div>
              <div className="p-2">
                <a
                  href="https://www.linkedin.com/company/xcltech/mycompany/"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <i class="bi bi-linkedin"></i>
                </a>
                {/* <img src={Google} width="20px" alt="img-icon" /> */}
              </div>
              <div className="p-2">
                <a
                  href="https://www.instagram.com/xcltechnologies/?hl=en"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <i class="bi bi-instagram"></i>
                </a>
                {/* <img src={Twitter} width="20px" alt="img-icon" /> */}
              </div>
              <div className="p-2">
                <a
                  href="https://twitter.com/xcltechnologies"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <i class="bi bi-twitter"></i>
                </a>
                {/* <img src={Pintrest} width="20px" alt="img-icon" /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 mt-5 footer_box1 ">
            {/* <ul style={{ listStyle: "none" }}> */}
            <h1>PAKISTAN</h1>
            <div style={{ display: "flex" }}>
              <div
                className="mr-3"
                style={{
                  borderRight: "2px solid #f67510",
                  paddingRight: "10px",
                }}
              >
                <p>
                  DEFENCE<br></br>HYDERABAD, SINDH <br></br> (022) 6111876
                </p>
              </div>
              <div className="ml-4" style={{ marginLeft: "10px" }}>
                <p>
                  House No:51-L<br></br>Block 2 P.E.C.H.S Karachi<br></br>+92
                  332 3549993
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 mt-5 footer_box2">
            <h1>UAE</h1>
            <p>
              HAMRIYA FREE ZONE<br></br>P.O BOX:49167 <br></br>+971 06 52 69112
            </p>
            {/* <ul style={{ listStyle: "none" }}>
              <h1>Services</h1>
              <li className="mt-2"> Software Development</li>
              <li className="mt-2"> App Development</li>
              <li className="mt-2"> Web Design & Development</li>
              <li className="mt-2"> Veiw All</li>
            </ul> */}
          </div>
          {/* <div className="col-lg-3 col-sm-12 mt-5 footer_box3 ">
            <h1>USA</h1>
            <p>
              VIENNA , VA<br></br>P.O BOX:22182 <br></br>+1 443 653 5673
            </p>
          
          </div> */}
        </div>
      </div>

      <div className="row">
        <p className=" mt-3 pb-3 text-center">
          © 2022 XCL Technologies - All Right Reserved
        </p>
      </div>
    </div>
  );
};

export default index;
