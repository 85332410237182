import React from "react";
import "./mobile.css";
import { AiFillHome } from "react-icons/ai";
import { FaBookmark } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { RiProductHuntFill } from "react-icons/ri";
import { MdWork } from "react-icons/md";
import { BsTelephonePlusFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const MobileMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="mobileScreen">
            <div className="menuBg">
              <div className="menuIcons">
                <div className="iconeList">
                  <button className="icon" onClick={() => navigate("/")}>
                    <AiFillHome />
                  </button>
                  <div className="iconName">Home</div>
                </div>
                <div className="iconeList">
                  <a href="#ISO">
                    <button className="icon">
                      <FaBookmark />
                    </button>
                  </a>
                  <div className="iconName">ISO</div>
                </div>
                <div className="iconeList">
                  <a href="#products">
                    <button className="icon">
                      <RiProductHuntFill />
                    </button>
                  </a>
                  <div className="iconName">Products</div>
                </div>
                <div className="iconeList">
                  <button
                    className="icon"
                    onClick={() => navigate("/services")}
                  >
                    <AiFillSetting />
                  </button>
                  <div className="iconName">Services</div>
                </div>
                <div className="iconeList">
                  <button className="icon" onClick={() => navigate("/work")}>
                    <MdWork />
                  </button>
                  <div className="iconName">Works</div>
                </div>
                <div className="iconeList">
                  <button className="icon" onClick={() => navigate("/contact")}>
                    <BsTelephonePlusFill />
                  </button>
                  <div className="iconName">Contact</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
