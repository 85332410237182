import React from "react";
import "./service.css";
import { Link } from "react-router-dom";
import { Line1 } from "../extraInfo/index";
import promotion from "../../Assets/promotion.png";
import Mobile from "../../Assets/app-development.png";
import HR from "../../Assets/human-resources.png";
import Software from "../../Assets/setting.png";
import Web from "../../Assets/icon.png";
import Networking from "../../Assets/networking icone.png";
import CMS from "../../Assets/online-shopping.png";
import Domain from "../../Assets/domain.png";
import Branding from "../../Assets/graphic desinging.png";
// import promotion from "../../Assets/promotion.png";
// import img2 from '../../Assets/Rectangle 68.png';
// import { CardData } from '../../Data/serviceCard.js';
// import { motion } from 'framer-motion';
import AnimatedText from "react-animated-text-content";

const index = () => {
  return (
    <div className="bg-img service-img">
      <div className="background-overlay">
        <AnimatedText
          type="words" // animate words or chars
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="float"
          interval={0.06}
          duration={1.0}
          tag="h1"
          className="animated-paragraph text-center service-heading"
          includeWhiteSpaces
          threshold={1.0}
          rootMargin="20%"
        >
          SERVICES
        </AnimatedText>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="sub-heading">
                Services is our Bussiness, <br></br> Perfection is our Goal,
                <br></br>Satisfication is our Gurantee.
              </h2>

              <p className="paragraph mt-4">
                At XCL Technologies, we are dedicated to delivering cutting-edge
                software solutions that help businesses transform and thrive.
                Our team of expert developers, designers, and project managers
                work tirelessly to create innovative products that meet the
                unique needs of our clients.
                <br></br>
                <br></br>
                We strongly believe in the power of the internet and technology
                to bring about changes, and we work as a team to make the best
                of it. If you are in search of a team with whom you can make a
                difference in your brand and business, you are in the right
                place.
              </p>
            </div>
            <div className="col-lg-6 col-md-6  m-auto">
              <div
                className="serviceBox"
                // style={{
                //   display: 'flex',
                //   flexDirection: 'row',
                //   flexWrap: 'wrap',
                //   paddingLeft: '70px',
                // }}
              >
                <Link
                  to={"/services/#component1"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Domain} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>Domain & Web Hosting</h1>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link
                  to={"/services"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={promotion} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>
                          Digital<br></br>Marketing
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/services"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Branding} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>
                          Branding & <br></br>Grahic Design
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/services"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Web} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>
                          Web Design & <br></br>Development
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
                <div
                  className="m-1 card service-card"
                  style={{ width: "180px" }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={CMS} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h1>CMS & E-commerce</h1>
                    </div>
                  </div>
                </div>
                <div
                  className="m-1 card service-card"
                  style={{ width: "180px" }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={HR} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h1>Human Resource</h1>
                    </div>
                  </div>
                </div>
                <Link
                  to={"/services"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Mobile} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>
                          App<br></br>Development
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/services"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        src={Networking}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h1>Networking & surveillance</h1>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/services/#component1"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className="m-1 card service-card"
                    style={{ width: "180px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={Software} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h1>Software Development</h1>
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <div
                  className="m-1 card service-card"
                  style={{ width: "200px" }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src="https://crabnetworkllp.com/wp-content/uploads/2021/03/VIDEO.png"
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h1>Artist</h1>
                    </div>
                  </div>
                </div> */}
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {" "}
                    <img
                      src="https://crabnetworkllp.com/wp-content/uploads/2021/03/service-final.svg"
                      alt=""
                    />
                  </div>
                  <div> yahan ay ga text</div>
                </div> */}
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div> yahan ay gi iamge</div>
                  <div> yahan ay ga text</div>
                </div> */}
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <div> yahan ay gi iamge</div>
                  <div> yahan ay ga text</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "44px" }}>
          <Line1 />
        </div>
      </div>
    </div>
  );
};

export default index;

// import React from "react";
// import "./service.css";
// import Rectangle from "../../Assets/Rectangle 67.png";
// import img2 from "../../Assets/Rectangle 68.png";
// import img3 from "../../Assets/Group 64.png";
// import { motion } from "framer-motion";
// import { Link } from "react-router-dom";
// const index = () => {
//   return (
//     <div className='bg-img'>
//       <h1 className='text-center s-headgin'>SERVICES WE PROVIDE</h1>
//       <div className='container'>
//         <div className='row gx-5'>
//           <div className='col-lg-3 col-md-6 col-sm-12 '>
//             <div
//               className='card mt-2 card1'
//               style={{ width: "18rem", height: "25rem" }}
//             >
//               <motion.img
//                 whileHover={{ scale: 1.3 }}
//                 referrerPolicy='no-referrer'
//                 src={Rectangle}
//                 alt='Product_image'
//                 width='50%'
//               />
//               <div className='card-body'>
//                 <h5 className='card-title'>Card title</h5>
//                 <p className='card-text'>
//                   Some quick example text to build on the card title and make up
//                   the bulk of the card's content.
//                 </p>
//                 <Link to='#' className='btn btn-primary'>
//                   Go somewhere
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3 col-md-6 col-sm-12 '>
//             <div
//               className='card mt-2 card2'
//               style={{ width: "18rem", height: "25rem" }}
//             >
//               <motion.img
//                 whileHover={{ scale: 1.3 }}
//                 referrerPolicy='no-referrer'
//                 src={img2}
//                 alt='Product_image'
//                 width='50%'
//               />

//               <div className='card-body'>
//                 <h5 className='card-title'>Card title</h5>
//                 <p className='card-text'>
//                   Some quick example text to build on the card title and make up
//                   the bulk of the card's content.
//                 </p>
//                 <Link to='#' className='btn btn-primary'>
//                   Go somewhere
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3 mt-2 col-md-6 col-sm-12 '>
//             <div
//               className='card card3'
//               style={{ width: "18rem", height: "25rem" }}
//             >
//               <motion.img
//                 whileHover={{ scale: 1.3 }}
//                 referrerPolicy='no-referrer'
//                 src={img3}
//                 alt='Product_image'
//                 width='50%'
//               />

//               <div className='card-body'>
//                 <h5 className='card-title'>Card title</h5>
//                 <p className='card-text'>
//                   Some quick example text to build on the card title and make up
//                   the bulk of the card's content.
//                 </p>
//                 <Link to='#' className='btn btn-primary'>
//                   Go somewhere
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className='col-lg-3 mt-2 col-md-6 col-sm-12 '>
//             <div
//               className='card card4'
//               style={{ width: "18rem", height: "25rem" }}
//             >
//               <motion.img
//                 whileHover={{ scale: 1.3 }}
//                 referrerPolicy='no-referrer'
//                 src={Rectangle}
//                 alt='Product_image'
//                 width='50%'
//               />

//               <div className='card-body'>
//                 <h5 className='card-title'>Card title</h5>
//                 <p className='card-text'>
//                   Some quick example text to build on the card title and make up
//                   the bulk of the card's content.
//                 </p>
//                 <Link to='#' className='btn btn-primary'>
//                   Go somewhere
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <br />
//       <div className='orange'>
//         <div className='container'>
//           <div className='row'>
//             <div className='col-lg-4 d-flex justify-content-center align-items-center'>
//               <div
//                 className='px-2'
//                 style={{ fontSize: "40px", fontWeight: "bold" }}
//               >
//                 31+{" "}
//               </div>
//               <div>Years of Experience</div>
//             </div>
//             <div className='col-lg-4 d-flex justify-content-center align-items-center'>
//               <div
//                 className='px-2'
//                 style={{ fontSize: "40px", fontWeight: "bold" }}
//               >
//                 74K{" "}
//               </div>
//               <div>Complete Projects</div>
//             </div>
//             <div className='col-lg-4 d-flex justify-content-center align-items-center'>
//               <div
//                 className='px-2'
//                 style={{ fontSize: "40px", fontWeight: "bold" }}
//               >
//                 2.2k{" "}
//               </div>
//               <div>Trusted Companies</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default index;
