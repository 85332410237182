import React from "react";
import "./navbar.css";
import Logo from "../../Assets/Component 23 – 2.png";
import Logo1 from "../../Assets/Component 23 – 1.png";
import { HiBars3 } from "react-icons/hi2";
import { Link } from "react-router-dom";
const index = () => {
  return (
    <div>
      <div className="container-fluid px-4 rlr-callout">
        <div className="row mt-4">
          <div
            className="col-4 logoImage"
            style={{ color: "white", fontSize: "17px" }}
          >
            <Link to={"/"}>
              <img src={Logo} className="Logo" alt="" />
            </Link>
          </div>{" "}
          <div
            className="col-6 logo-hover"
            style={{ color: "white", fontSize: "17px" }}
          >
            <Link to={"/"}>
              <img src={Logo1} className="Logo1" alt="" />
            </Link>
          </div>
          <div className="col-8 navbar-left">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
              <div className="container-fluid" style={{ marginLeft: "5px" }}>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {/* <span className="navbar-toggler-icon" /> */}
                  <HiBars3 className="lineBars" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav  mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="/"
                        style={{ color: "white", fontSize: "20px" }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      {/* <Link
                        className="nav-link"
                        to="/"
                        style={{ color: "white", fontSize: "20px" }}
                      > */}

                      <a
                        href="#About"
                        className="nav-link"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          textDecoration: "none",
                        }}
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      {/* <Link
                        className="nav-link"
                        to="/"
                        style={{ color: "white", fontSize: "20px" }}
                      > */}

                      <a
                        href="#clients"
                        className="nav-link"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          textDecoration: "none",
                        }}
                      >
                        Clients
                      </a>
                    </li>
                    <li className="nav-item">
                      {/* <Link
                        className="nav-link"
                        to="/"
                        style={{ color: "white", fontSize: "20px" }}
                      > */}

                      <a
                        href="#products"
                        className="nav-link"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          textDecoration: "none",
                        }}
                      >
                        Products
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/services"
                        style={{ color: "white", fontSize: "20px" }}
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/work"
                        style={{ color: "white", fontSize: "20px" }}
                      >
                        Work
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/work"
                        style={{ color: "white", fontSize: "20px" }}
                      >
                        Products
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/contact"
                        style={{ color: "white", fontSize: "20px" }}
                      >
                        Contact
                      </Link>
                    </li>{" "}
                    <li className="nav-item"></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div id="mySidenav">
          <a className="nav-link" href="#ISO">
            <button className="btn navbar-button">ISO certified</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default index;
