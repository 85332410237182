import React from "react";
import AnimatedText from "react-animated-text-content";
import { motion } from "framer-motion";
import Client1 from "../../Assets/client-icons/11.png";
import Client2 from "../../Assets/client-icons/12.png";
import Client3 from "../../Assets/client-icons/13.png";
import Client4 from "../../Assets/client-icons/14.png";
import Client5 from "../../Assets/client-icons/16 [2].png";
import Client22 from "../../Assets/client-icons/Rectangle 3 copy 2.png";
import Client21 from "../../Assets/client-icons/fwa.png";

import Client6 from "../../Assets/client-icons/17.png";
import Client7 from "../../Assets/client-icons/18.png";
import Client8 from "../../Assets/client-icons/21.png";
import Client9 from "../../Assets/client-icons/22.png";
import Client10 from "../../Assets/client-icons/23.png";
import Client11 from "../../Assets/client-icons/24.png";
import Client12 from "../../Assets/client-icons/25.png";
import Client13 from "../../Assets/client-icons/35.png";
import Client14 from "../../Assets/client-icons/28.png";
import Client15 from "../../Assets/client-icons/29.png";
import Client16 from "../../Assets/client-icons/30.png";
import Client17 from "../../Assets/client-icons/31.png";
import Client18 from "../../Assets/client-icons/33.png";
import Client19 from "../../Assets/client-icons/ad.png";
import Client20 from "../../Assets/client-icons/afsd.png";
import Client23 from "../../Assets/client-icons/34.png";
import Client24 from "../../Assets/client-icons/Rectangle 3.png";

const index = () => {
  const data = [
    {
      img1: Client24,
    },
    {
      img1: Client13,
    },
    {
      img1: Client22,
    },
    {
      img1: Client6,
    },
    {
      img1: Client7,
    },
    {
      img1: Client20,
    },
    {
      img1: Client8,
    },
    {
      img1: Client5,
    },
    {
      img1: Client2,
    },
    {
      img1: Client3,
    },
    {
      img1: Client4,
    },

    {
      img1: Client9,
    },
    {
      img1: Client10,
    },
    {
      img1: Client11,
    },
    {
      img1: Client12,
    },

    {
      img1: Client14,
    },

    {
      img1: Client15,
    },
    {
      img1: Client16,
    },
    {
      img1: Client17,
    },
    {
      img1: Client18,
    },
    {
      img1: Client19,
    },

    {
      img1: Client21,
    },

    {
      img1: Client23,
    },
    {
      img1: Client1,
    },
  ];
  return (
    <div id="clients">
      <div className="fluid our-client">
        <div className="row pt-2 text-center">
          <AnimatedText
            type="words" // animate words or chars
            animation={{
              x: "200px",
              y: "-20px",
              scale: 1.1,
              ease: "ease-in-out",
            }}
            animationType="float"
            interval={0.06}
            duration={1.0}
            tag="h1"
            className="animated-paragraph text-center"
            includeWhiteSpaces
            threshold={1.0}
            rootMargin="20%"
          >
            OUR CLIENTS
          </AnimatedText>
        </div>
        <div className="container">
          <div className="row">
            {data.map((item, index) => {
              return (
                <div className=" gx-2 col-lg-2 col-6 col-sm-6  mb-2">
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    referrerPolicy="no-referrer"
                    src={item.img1}
                    alt="Product_image"
                    width="100%"
                    height={"100%"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
