import HumanResource from "../Assets/hr-filter.png";
import DigitalMarketing from "../Assets/digital-filter.png";
import DomainHosting from "../Assets/internet-g135072b88_640-removebg-preview.png";
import Branding from "../Assets/branding-designing.png";
import Software from "../Assets/image-removebg-preview (2).png";
import Mobile from "../Assets/modfaj.png";
import Networking from "../Assets/networking.png";

// gallery data
// import Random1 from "../Assets/"
import Random1 from "../Assets/xcl/all/MOCKUPSAD-01.jpg";
import Random2 from "../Assets/xcl/all/mockupxcl-01.png";
import Random3 from "../Assets/xcl/all/mockupxclertgd-01.jpg";
import Random4 from "../Assets/xcl/all/mockupxclhjlukj,m-01.jpg";
import Random5 from "../Assets/xcl/all/mockupxclx cx-01.jpg";
import Random6 from "../Assets/xcl/all/dsfvv-01.jpg";
import Digital1 from "../Assets/digital1.jpg";
import Digital2 from "../Assets/digital2.jpg";
import Digital3 from "../Assets/digital3.jpg";
import Digital4 from "../Assets/digital4.jpg";
import Digital5 from "../Assets/digital5.jpg";
import Digital6 from "../Assets/digital6.jpg";
import SEO1 from "../Assets/seo1.jpg";
import SEO2 from "../Assets/seo2.jpg";
import SEO3 from "../Assets/seo3.jpg";
import SEO4 from "../Assets/seo4.jpg";
import SEO5 from "../Assets/seo5.jpg";
import SEO6 from "../Assets/seo6.jpg";
import Branding1 from "../Assets/brading1.jpg";
import Branding2 from "../Assets/branding2.jpg";
import Branding3 from "../Assets/brading3.jpg";
import Branding4 from "../Assets/branding4.jpg";
import Branding5 from "../Assets/branding5.jpg";
import Branding6 from "../Assets/branding6.jpg";
import Travel from "../Assets/travel1.jpg";
import Travel2 from "../Assets/travel2.jpg";
import Travel3 from "../Assets/travel3.jpg";
import Travel4 from "../Assets/travel4.jpg";
import Travel5 from "../Assets/travel5.jpg";
import Travel6 from "../Assets/travel6.jpg";
import Ecommerce1 from "../Assets/ecommerce1.jpg";
import Ecommerce2 from "../Assets/ecommerce2.jpg";
import Ecommerce3 from "../Assets/ecommerce3.jpg";
import Ecommerce4 from "../Assets/ecommerce4.jpg";
import Ecommerce5 from "../Assets/ecommerce5.jpg";
import Ecommerce6 from "../Assets/ecommerce6.jpg";

export const filterData = [
  {
    id: 1,
    heading: "Software Development",
    img: Software,
    content:
      "XCL Technologies has a team of Desktop application experts which loop on the latest in software technologies to convert your dreams of express service to your customers into reality. We help everyone who wants desktop or web applications and software development. It doesn’t matter if you have just a software idea, if it is a solid one, we can help you with cross-platform and platform-specific desktop application development and software development services. Why is it a good idea to have a desktop application and software development? There is nothing better to outdo competitors and to get the customer loyalty and an authentication stamp on your business.",
  },
  {
    id: 2,
    heading: "Branding & Graphic Designing",
    img: Branding,
    content:
      "XCL TECHNOLOGIES specializes in creating unique, high-end web designs, print, and multimedia. Our talented staff is constantly striving to deliver products that exceed your expectations. Our goal is to make you stand out with style and be remembered.We strongly believe in the power of the internet and technology to bring about changes, and we work as a team to make the best of it. If you are in search of a team with whom you can make a difference in your brand and business, you are in the right place",
  },
  {
    id: 3,
    heading: "Web Design & Development",
    img: Software,
    content:
      "We have got a colorful team with a dynamic imagination. You tell us what your business is all about and see the magic of web presence we create. Whatever services you offer or the product that you have, we use that to design and develop bespoke websites that speak to the business you provide. From varied screen sizes in multiple browsers and user-friendly interface, our websites scream originality. Get a complete website with hosting and theme that is relevant to your business and will help you with ever-changing web trends in a long run. XCL Technologies specializes in website design and development services.",
  },
  {
    id: 4,
    heading: "Network & surveillance",
    img: Networking,
    content:
      "At XCL we can help businesses design and implement a network and server infrastructure that meets their specific needs. This includes selecting hardware and software, configuring the network, and optimizing performance.We can also help businesses secure their network and server infrastructure by implementing security measures such as firewalls, intrusion detection and prevention systems, and access controls. They can also help organizations develop and implement security policies and procedures.",
  },
  {
    id: 5,
    heading: "Mobile Application Development",
    img: Mobile,
    content:
      "At XCL, we specialize in building high-quality mobile apps for iOS and Android. Our team of experienced developers, designers, and project managers work together to create custom solutions tailored to your business needs.We use cutting-edge technologies and the latest development practices to ensure that our apps are fast, reliable, and easy to use. Our mobile apps are designed to enhance your brand, engage your customers, and increase your revenue.",
  },
  {
    id: 6,
    heading: "Domain & Web Hosting",
    img: DomainHosting,
    content:
      "Ready to take your online presence to the next level? Contact us today to learn more about our domain registration and web hosting services. Our team of experts is standing by to help you find the perfect solution for your needs.At XCL, we're passionate about helping our clients succeed online.Our web hosting service provides reliable and secure hosting for your website. We offer a variety of hosting plans, from basic shared hosting to more advanced VPS and dedicated server options. Our hosting plans are designed to meet the needs of businesses and individuals of all sizes, and we're committed to providing fast loading times and maximum uptime for your website.",
  },
  {
    id: 7,
    heading: "Digital Marketing",
    img: DigitalMarketing,
    content:
      "At XCL, we understand the importance of digital marketing in today's business landscape. That's why we offer a comprehensive suite of digital marketing services to help our clients achieve their online marketing goals.Our team of marketing experts has years of experience in developing and executing successful digital marketing campaigns. We use a data-driven approach to ensure that our strategies are effective, measurable, and adaptable to changing market conditions ",
  },
  {
    id: 8,
    heading: "Human Resource",
    img: HumanResource,
    content:
      " At XCL Technologies, we can provide a variety of HR-related services to help your clients manage their workforce more effectively and efficiently. we provide consulting services on HR-related topics such as employee engagement, performance management, talent acquisition, compensation and benefits, and compliance with employment laws.",
  },
  {
    id: 9,
    heading: "CMS & E-commerce",
    img: Software,
    content:
      "At XCL, we offer a range of content management system (CMS) and e-commerce solutions that help businesses streamline their online operations and increase their revenue. Our team of experienced developers, designers, and project managers work together to create custom solutions that meet the specific needs of your business.lo",
  },
];

export const galleryImages = [
  {
    id: 1,
    name: "ALL",
    img: Random2,
  },
  {
    id: 1,
    name: "ALL",
    img: Random4,
  },
  {
    id: 1,
    name: "ALL",
    img: Random1,
  },
  {
    id: 1,
    name: "ALL",
    img: Random5,
  },
  {
    id: 1,
    name: "ALL",
    img: Random6,
  },
  {
    id: 1,
    name: "ALL",
    img: Random3,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital1,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital2,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital3,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital4,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital5,
  },
  {
    id: 2,
    name: "Digital",
    img: Digital6,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding1,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding5,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding4,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding3,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding2,
  },
  {
    id: 3,
    name: "Branding",
    img: Branding6,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel2,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel3,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel4,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel5,
  },
  {
    id: 4,
    name: "Travel",
    img: Travel6,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO1,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO2,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO3,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO4,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO5,
  },
  {
    id: 5,
    name: "SEO",
    img: SEO6,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce1,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce2,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce3,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce4,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce5,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: Ecommerce6,
  },
];

export const workData = [
  {
    id: 1,
    name: "ALL",
    heading: "Ministry Of Interior KSA",
    text: "Web & App Development",
    img: Random1,
    link: "#workHeader",
  },
  {
    id: 1,
    name: "ALL",
    heading: "Liaquat University Hospital",
    text: "Website Design & Development",
    img: Random2,
    link: "https://luh.gos.pk/",
  },
  {
    id: 1,
    name: "ALL",
    heading: "Dubai Police",
    text: "Logo Design & Branding",
    img: Random3,
    link: "https://www.dubaipolice.gov.ae/wps/portal/home",
  },
  {
    id: 1,
    name: "ALL",
    heading: "Syed Abdullah Shah Hospital",
    text: "Website Design & Development.",
    img: Random4,
    link: "http://sasims.gos.pk/",
  },
  {
    id: 1,
    name: "ALL",
    heading: "Emirates Lighting",
    text: "Website Design & Development",
    img: Random5,
    link: "https://xcl-project-bf537.web.app/",
  },
  {
    id: 1,
    name: "ALL",
    heading: "Q-Fashion",
    text: "E-commerce Solution",
    img: Random6,
    link: "https://www.qfashiontrends.com/",
  },
  {
    id: 2,
    name: "Digital",
    heading: "Online Booking Website",
    text: "Website Design & Development",
    img: Digital2,
    link: "#workHeader",
  },
  {
    id: 2,
    name: "Digital",
    heading: "Best Western Premier Hotel Dubai",
    text: "Website Design & Development",
    img: Digital3,
    link: "#workHeader",
  },
  {
    id: 2,
    name: "Digital",
    heading: "Action to Action",
    text: "Website Design & Development",
    img: Digital4,
    link: "#workHeader",
  },
  {
    id: 2,
    name: "Digital",
    heading: " The View Albesha",
    text: "Booking Website with Online Payment",
    img: Digital5,
    link: "#workHeader",
  },
  {
    id: 2,
    name: "Digital",
    heading: " SPARK WTP",
    text: "Static Website Design",
    img: Digital6,
    link: "#workHeader",
  },
  {
    id: 3,
    name: "Branding",
    heading: " JF Constructions",
    text: "Logo design/Contractors software",
    img: Branding1,
    link: "#workHeader",
  },
  {
    id: 3,
    name: "Branding",
    heading: "Alba Tech",
    text: "Logo Design / Branding",
    img: Branding5,
    link: "#workHeader",
  },

  {
    id: 3,
    name: "Branding",
    heading: " University of Rock",
    text: " Company Branding",
    img: Branding3,
    link: "#workHeader",
  },
  {
    id: 3,
    name: "Branding",
    heading: " Electrofos",
    text: "Brochure Designing",
    img: Branding2,
    link: "#workHeader",
  },
  {
    id: 3,
    name: "Branding",
    heading: "Paratha and Karak",
    text: "Logo design/Digital marketing",
    img: Branding6,
    link: "#workHeader",
  },

  {
    id: 4,
    name: "Travel",
    heading: "Friends Travel",
    text: "Website Design & Development",
    img: Travel2,
    link: "#workHeader",
  },

  {
    id: 4,
    name: "Travel",
    heading: " The View Albesha",
    text: "Booking Website with Online Payment",
    img: Travel4,
    link: "#workHeader",
  },
  {
    id: 4,
    name: "Travel",
    heading: "Relax Tourism",
    text: "Website Design & Development",
    img: Travel5,
    link: "#workHeader",
  },

  {
    id: 5,
    name: "SEO",
    heading: " IITS",
    text: "Digital Marketing",
    img: SEO1,
    link: "#workHeader",
  },
  {
    id: 5,
    name: "SEO",
    heading: "Alpha Pet Foods",
    text: "Ecommerce Website and SEO Optimzation",
    img: SEO2,
    link: "#workHeader",
  },
  {
    id: 5,
    name: "SEO",
    heading: "FROM.AE",
    text: " E-Commerce Website with Digital Marketing & SEO",
    img: SEO3,
    link: "#workHeader",
  },
  {
    id: 5,
    name: "SEO",
    heading: " Elyssa Perfumes",
    text: " Ecommerce Website and SEO Optimzation",
    img: SEO4,
    link: "#workHeader",
  },

  {
    id: 6,
    name: "Ecommerce",
    heading: " Perfume Products Website",
    text: "Website Design and Development",

    img: Ecommerce1,
    link: "#workHeader",
  },
  {
    id: 6,
    name: "Ecommerce",
    heading: "ALBADEEL",
    text: "E-Commerce Website",
    img: Ecommerce2,
    link: "#workHeader",
  },

  {
    id: 6,
    name: "Ecommerce",
    heading: " Cheatue De Diamon",
    text: "E-Commerce Website",
    img: Ecommerce6,
    link: "#workHeader",
  },
  {
    id: 4,
    name: "Travel",
    heading: "Western Hotels and Resorts",
    text: "Branding / Content Writing and Presentation",
    img: Travel6,
    link: "#workHeader",
  },
  {
    id: 4,
    name: "Travel",
    heading: "Online Booking Website / Portal",
    text: " Website Design, Development, Online Instant Booking System ( b2c, b2b)",
    img: Travel3,
    link: "#workHeader",
  },
  {
    id: 4,
    name: "Travel",
    heading: "Alyouser Intl. Travel and Tourism",
    text: "Website Design&Development with CMS",
    img: Travel,
    link: "#workHeader",
  },
];
