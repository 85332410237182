import "./App.css";
import { useState, useEffect, Suspense } from "react";
import Routes from "./Routes/index";
import MobileMenu from "./component/MobileMenu/MobileMenu";
import PropagateLoader from "react-spinners/PropagateLoader";
function App() {
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);
  return (
    <>
      {/* {loading ? (
        <>
          <PropagateLoader
            color="#f67510"
            size={30}
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          />
          <h1 className="text-center" style={{ marginTop: "-300px" }}>
            XCL TECHNOLOGIES...
          </h1>
        </>
      ) : ( */}
      <>
        <Routes />
        <MobileMenu />
      </>
      {/* )} */}
    </>
  );
}
export default App;
