import React, { useEffect, useState } from "react";
import "./gallery.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Pagination from "./pagination";
import OurClient from "../ourClient/index";
import Product1 from "../../Assets/our-products/p1.png";
import Product2 from "../../Assets/our-products/p2.png";
import Product3 from "../../Assets/our-products/p3.png";
import Product4 from "../../Assets/our-products/p4.png";
import Product5 from "../../Assets/our-products/p5.png";
import Product6 from "../../Assets/our-products/p6.png";
import Product7 from "../../Assets/our-products/p7.png";
import Product8 from "../../Assets/our-products/p8.png";
import Product9 from "../../Assets/our-products/p9.png";
import Product10 from "../../Assets/taxilao.jpg";
import Product11 from "../../Assets/Contract-Management.jpg";
import Product12 from "../../Assets/download.jfif";
import AnimatedText from "react-animated-text-content";
import { galleryImages } from "../../Data/api";

const Index = () => {
  const [filterData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage, setProductPerPage] = useState(6);
  // porduct data
  const data = [
    {
      img1: Product1,
      heading: "Digital Health Care System",
      description:
        "We have developed “Hospital Management System”. The main aim of our Project is to provide a paper-less Hospital up to 90%. It covers administrative, financial, in-patient & out-patient, inventory, pharmacy management, clinicalrequirements etc.",
    },
    {
      img1: Product2,
      heading: "Digital Breed Program",
      description:
        "Any Activity, can be recorded to get smart and better results from the resources.Not only that, we can easily record as many activities or cases they deal along with a picture, which they can upload with their mobile App, will submit by every sub offices and their employees working in the field.",
    },
    {
      img1: Product3,
      heading: "Hotel Management System",
      description:
        "XCL offers a Hotel Central Reservation & Management System which gives end to end solutions to provide facilities from small to highranged Hotels, Resorts, Apartments, Lodges and all other.  ",
    },
    {
      img1: Product4,
      heading: "Integrated Education Software Services",
      description:
        "IESS is a  powerful tools that enables different educational institutions in harmonizing the flow of knowledge and facilitate the quest for information while solving the communication gap with a user friendly and untroublesome school management software system",
    },
    {
      img1: Product5,
      heading: "Real Estate Management System",
      description:
        "Each property is unique and when it comes to real estate there are numerous factors to be taken into consideration. These factors can be recorded, organized and prioritized according to your needs",
    },
    {
      img1: Product6,
      heading: "Petrol Station",
      description:
        "Need to manage petrol/fuel levels? Or perhaps you need to see past reports for comparison? All this and more can be found when using one of XCL Technologies’ tools. With an easy to use UI, and a simplified design that can be customized to meet your needs.",
    },
    {
      img1: Product7,
      heading: "Inventory Management System",
      description:
        "For those busy businesses that need to buy and manage stock, goods, and merchandise on a day-to-day basis,much of their say is spent on inventory management. That is, keeping track of the level of supply and placing new orders constantly.",
    },
    {
      img1: Product8,
      heading: "Restaurant Management System",
      description:
        "Every day a restaurant is filled with a variety of activities, that can impact customer experience and keeps the restaurant owner and employees busy and stressed. Our system integrates smart order management, customizable menus, and convenient payment processing.",
    },
    {
      img1: Product9,
      heading: "Digital Nation",
      description:
        "Campaigning for political parties involves making rigorous marketing strategies to magnetize votes from the citizens and thereby win the elections. These marketing strategies have been experimented with and are expanding over time on various fronts.  ",
    },
    {
      img1: Product10,
      heading: "Taxi Lao",
      description:
        "TAXI Lao is much more than digital transportation & Logistic services, this model in particular through making better use of the opportunities offered by digital technology. Focusing on the creation of solutions and value-added services, on top of cost reduction, are driving digital transformations. ",
    },
    {
      img1: Product12,
      heading: "eCommerce Solutions",
      description:
        "we offer a complete portfolio of professional ecommerce Services encompassing ecommerce consulting, design, implementation, systems integration, hosting, training, and support services to add the delivery of world-class ecommerce solutions based on our flagship E-commerce platform.",
    },
    {
      img1: Product11,
      heading: "Civil-Contract Management System",
      description:
        "With XCL, the Govt Contractor will have a smart and most advance solution to manage and run the procedures including, suppliers management, Purchasing, transfering fund, Manager activities, rentel equipments, loading off loading confirmation, labour salaries and day to day expenses all the necessary information .",
    },
  ];

  useEffect(() => {
    // const AllWatchItems = async () => {
    const Data = galleryImages.map((doc) => doc);
    setFilterData(Data);
  }, []);
  const indexOfLastPage = currentPage * productPerPage;
  const indexOfFirstPage = indexOfLastPage - productPerPage;
  const currentPosts = filterData.slice(indexOfFirstPage, indexOfLastPage);

  const filter = (name) => {
    const data = galleryImages.filter((item) => item.id == name);

    setFilterData(data);
  };

  return (
    <div className='bg-img'>
      <div className='container'>
        {/* row 1 */}
        <div className='row explore-work'>
          <div className='col-lg-12 Explore'>
            <AnimatedText
              type='words' // animate words or chars
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType='float'
              interval={0.06}
              duration={1.0}
              tag='h1'
              className='animated-paragraph '
              includeWhiteSpaces
              threshold={1.0}
              rootMargin='20%'
            >
              EXPLORE OUR WORK
            </AnimatedText>
          </div>
        </div>
        {/* row 2 */}
        <div className='row sub-text'>
          <div className='col-lg-12'>
            <div className='build-para'>
              <p className='text-center'>
                Build your brand with an insight-driven advertising agency that
              </p>
              <p className='text-center'>
                believes in taking action. Read on to discover just how.
              </p>
            </div>
            <div className='sub-links'>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("1");
                  }}
                >
                  ALL
                </button>
              </p>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("2");
                  }}
                >
                  Software
                </button>
              </p>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("3");
                  }}
                >
                  Branding
                </button>
              </p>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("4");
                  }}
                >
                  Digital
                </button>
              </p>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("5");
                  }}
                >
                  E-Commerce
                </button>
              </p>
              <p>
                <button
                  className='cus-menu-tabs'
                  onClick={() => {
                    filter("6");
                  }}
                >
                  SEO
                </button>
              </p>
            </div>
          </div>
        </div>
        {/* row 3 */}
        <div className='row'>
          {currentPosts.map((item, index) => {
            return (
              <div className='col-lg-4 gx-2'>
                <div className='my-2 '>
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    referrerPolicy='no-referrer'
                    src={item.img}
                    alt='Product_image'
                    width={"100%"}
                    height={"auto"}
                  />
                </div>
              </div>
            );
          })}
          {/* <div className="ml-auto my-4 mr-4">
            <Pagination
              productPerPage={productPerPage}
              totalProduct={data.length}
              paginate={Index}
            />{" "}
          </div> */}
          {/* <div className="col-lg-6 gx-2">
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G1}
                alt="Product_image"
                width="100%"
              />
            </div>
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G3}
                alt="Product_image"
                width="100%"
              />
            </div>
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G5}
                alt="Product_image"
                width="100%"
              />
            </div>
          </div>
          <div className="col-lg-6 gx-2">
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G2}
                alt="Product_image"
                width="100%"
              />
            </div>
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G4}
                alt="Product_image"
                width="100%"
              />
            </div>
            <div className="my-2">
              <motion.img
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrer"
                src={G6}
                alt="Product_image"
                width="100%"
              />
            </div>
          </div> */}
        </div>
        <br></br>
      </div>
      <br></br>

      <OurClient />

      {/* our products */}
      <div className='row mt-4 product-heading' id='products'>
        <AnimatedText
          type='words' // animate words or chars
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType='float'
          interval={0.06}
          duration={1.0}
          tag='h1'
          className='animated-paragraph text-center '
          includeWhiteSpaces
          threshold={1.0}
          rootMargin='20%'
        >
          OUR PRODUCTS
        </AnimatedText>
        {/* <h1 className="text-center"></h1> */}
      </div>
      <div className='container product-gallery'>
        <div className='row'>
          {data.map((item, index) => {
            return (
              <div className='gx-2 col-lg-4 mt-2 '>
                <div class='product-card'>
                  <div class='product-card-img'>
                    <h2>{item.heading}</h2>
                    <img
                      src={item.img1}
                      style={{ width: "100%", height: "100%" }}
                      alt=''
                    />
                  </div>
                  <div class='product-card-info'>
                    <p class='text-title'>{item.heading}</p>
                    <p class='text-body text-center'>{item.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="col-lg-4 my-3">
            <div class="product-card">
              <div class="product-card-img">
                <img src={c2} width="100%" alt="" />
              </div>
              <div class="product-card-info">
                <p class="text-title">XCL Technologies</p>
                <p class="text-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum iusto maxime consequatur dolore similique quibusdam,
                  ratione{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div class="product-card">
              <div class="product-card-img">
                <img src={c3} width="100%" alt="" />
              </div>
              <div class="product-card-info">
                <p class="text-title">XCL Technologies</p>
                <p class="text-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum iusto maxime consequatur dolore similique quibusdam,
                  ratione{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div class="product-card">
              <div class="product-card-img">
                <img src={c4} width="100%" alt="" />
              </div>
              <div class="product-card-info">
                <p class="text-title">XCL Technologies</p>
                <p class="text-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum iusto maxime consequatur dolore similique quibusdam,
                  ratione{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div class="product-card">
              <div class="product-card-img">
                <img src={c5} width="100%" alt="" />
              </div>
              <div class="product-card-info">
                <p class="text-title">XCL Technologies</p>
                <p class="text-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum iusto maxime consequatur dolore similique quibusdam,
                  ratione{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div class="product-card">
              <div class="product-card-img">
                <img src={c6} width="100%" alt="" />
              </div>
              <div class="product-card-info">
                <p class="text-title">XCL Technologies</p>
                <p class="text-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Illum iusto maxime consequatur dolore similique quibusdam,
                  ratione{" "}
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
