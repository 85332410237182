import React from "react";
import "./extraInfo.css";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Line1 = () => {
  return (
    <div>
      <div className="orange">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <div
                className="px-2"
                style={{ fontSize: "40px", fontWeight: "bold" }}
              >
                20+{" "}
              </div>
              <div>Years of Experience</div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <div
                className="px-2"
                style={{ fontSize: "40px", fontWeight: "bold" }}
              >
                25K{" "}
              </div>
              <div>Complete Projects</div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <div
                className="px-2"
                style={{ fontSize: "40px", fontWeight: "bold" }}
              >
                2.2k{" "}
              </div>
              <div>Trusted Companies</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Lin2 = () => {
  return (
    <div>
      {" "}
      <div className="orange ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h1 className="mt-3 idea">HAVE AN IDEA? </h1>
              <h3 className="sub-heading " style={{ fontSize: "30px" }}>
                We can help you to bring it life.
              </h3>
            </div>
            <div className="col-lg-6 col-12">
              <Link
                to={"/contact"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <h4 className="text-center mt-5 gett--in--touch">
                  Get in touch
                  <FaArrowRight
                    style={{ fontSize: "18px", margin: "10px 10px" }}
                  />
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
