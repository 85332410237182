import React from "react";
import "./cards.css";
import Icon1 from "../../Assets/creative.png";
import Icon2 from "../../Assets/icon-2.png";
import Icon3 from "../../Assets/icon-3.png";
import { motion } from "framer-motion";

const index = () => {
  return (
    <div>
      <div className="container simple-cards">
        <div className="row">
          <div className="col-lg-4">
            <div
              className="card bg-transparent"
              style={{ width: "75%", margin: "auto" }}
            >
              <motion.img
                className="card-img-top my-img"
                whileHover={{ scale: 1.2 }}
                src={Icon1}
                alt="img2"
              />
              <div className="card-body">
                <h2 className="card-title card-heading mb-3">CREATIVE</h2>
                <p className="card-text card-subbody mt-4">
                  With our wild minds and disciplined eyes, our creativity is
                  naturally connected to our enthusiasm.
                </p>
              </div>
            </div>
          </div>
          {/* 2nd card */}
          <div className="col-lg-4">
            <div
              className="card bg-transparent"
              style={{ width: "75%", margin: "auto" }}
            >
              <motion.img
                className="card-img-top my-img"
                whileHover={{ scale: 1.2 }}
                src={Icon2}
                alt="img2"
              />
              <div className="card-body">
                <h2 className="card-title card-heading mb-3">INNOVATIVE</h2>
                <p className="card-text card-subbody mt-4">
                  We push beyond our limits to make each of our works stand out
                  and bring out something new
                </p>
              </div>
            </div>
          </div>
          {/* 3rd card */}

          <div className="col-lg-4">
            <div
              className="card bg-transparent"
              style={{ width: "75%", margin: "auto" }}
            >
              <motion.img
                className="card-img-top my-img"
                whileHover={{ scale: 1.2 }}
                src={Icon3}
                alt="img2"
              />

              <div className="card-body">
                <h2 className="card-title card-heading mb-3">PROACTIVE</h2>
                <p className="card-text card-subbody mt-4">
                  Our Proactive culture simplifying leadership, increase
                  positive results and build a more joyful workplace.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
