import React from "react";
import "./component1.css";
import { workData } from "../../../Data/api";

const index = () => {
  return (
    <div>
      <div className="container">
        <div className="row  mt-5">
          {workData.map((item, index) => {
            return (
              <div className="d-flex  col-lg-4 col-md-6 col-12 mt-4">
                <a href={item.link} style={{ textDecoration: "none" }}>
                  <div
                    className="card work_card"
                    style={{ width: "25rem", height: "auto" }}
                  >
                    <img src={item.img} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h2 className="text-center work-page-card-heading">
                        {item.heading}
                      </h2>
                      <p className="card-subtext"> {item.text}</p>
                      {/* <h5 className="card-title text-center">Card title</h5> */}

                      <p></p>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      {/* 1st component */}
      {/* <div className="fluid component1">
        <div className="container pt-5">
          <div className=" q-fashion row mt-5">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1>Q-Fashion</h1>

              <p className="mt-3" style={{ textAlign: "justify" }}>
                The best online shoping store in Qatar is qfashiontrends.com.
                Anywhere in Doha can receive our deliveries for free. We at
                qfashiontrends are committed to making it possible for customers
                to find a variety of products at affordable costs.
              </p>
              <div className="row mt-5">
                <div className="col-6 ">
                  <h1>Year</h1>
                  <h3>2022</h3>
                </div>
                <div className="col-6 ">
                  <h1>Role</h1>
                  <h3>Design & Development</h3>
                </div>
              </div>
              <button className="Details ">Details</button>
            </div>
            <div className="col-lg-6 col-md-12  col-sm-12 d-flex justify-content-end">
              <img src={Sideimg1} className="q-fashion-img2" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      {/* 2nd component */}
      {/* <div className="fluid component2 ">
        <div className="container pt-5">
          <div className=" febcare row mt-5">
            <div className="col-lg-6 col-md-12 ">
              <img src={Febcare} className="Febcare-img1" alt="" />
              <img src={Febcareimg2} width="50%" alt="" />
            </div>
            <div className="col-lg-6 col-md-12">
              <h1>Fabcare</h1>

              <p className="mt-3" style={{ textAlign: "left" }}>
                Fabcare is the elderly homecare services providing company in
                london, the team specialised in helping people with old age who
                need carers at home like a family member. Our services ranging
                from check-in visits to complete live- in support.
              </p>
              <div className="row mt-5">
                <div className="col-6 ">
                  <h1>Year</h1>
                  <h3>2022</h3>
                </div>
                <div className="col-6 ">
                  <h1>Role</h1>
                  <h3>Design & Development</h3>
                </div>
              </div>
              <button className="Details ">Details</button>
            </div>
          </div>
        </div>
      </div> */}
      {/* 3rd component */}
      {/* <div className="fluid component1">
        <div className="container pt-5">
          <div className=" q-fashion row mt-5">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1>Dawood Super Mart</h1>

              <p className="mt-3" style={{ textAlign: "justify" }}>
                Choose from a wide range of options in every category,
                exclusively handpicked to help you find the best quality
                available at the lowest prices. We can guarantee our customers
                on timely delivery, No hidden cost and the best quality
                available.
              </p>
              <div className="row mt-5">
                <div className="col-6 ">
                  <h1>Year</h1>
                  <h3>2022</h3>
                </div>
                <div className="col-6 ">
                  <h1>Role</h1>
                  <h3>Design & Development</h3>
                </div>
              </div>
              <button className="Details ">Details</button>
            </div>
            <div className="col-lg-6 col-md-12  col-sm-12 d-flex justify-content-end">
              <img src={Sideimg1} className="q-fashion-img2" alt="" />
            </div>
          </div>
        </div>
      </div> */}
      {/* 4rth component */}
      {/* <div className="fluid component2 py-4">
        <div className="container pt-5">
          <div className=" febcare row mt-5">
            <div className="col-lg-6 col-md-12 ">
              <img src={Cusinetonite} className="cusine_tonite_img" alt="" />
              <img src={Febcareimg2} width="50%" alt="" />
            </div>
            <div className="col-lg-6 col-md-12">
              <h1>Cuisine Tonite</h1>

              <p className="mt-3" style={{ textAlign: "left" }}>
                The restaurant opened in an effort to provide food with a unique
                narrative. A pleasant environment scene is combined with a
                delectable cuisine menu to create unforgettable moments here!
              </p>
              <div className="row mt-5">
                <div className="col-6 ">
                  <h1>Year</h1>
                  <h3>2023</h3>
                </div>
                <div className="col-6 ">
                  <h1>Role</h1>
                  <h3>Design & Development</h3>
                </div>
              </div>
              <button className="Details ">Details</button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default index;
