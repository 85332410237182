import React from "react";
import "./aboutHeader.css";
// import sideImage from "../../Assets/Component 22 – 1.png";
// import Video from "../../Assets/untitled_zg8F8vkg.mp4";
import Video from "../../../Assets/about-video.mp4";
// import headerBg from '../../../Assets/Mask Group 18.png';
// import Navbar from "";
import AboutCard from "../AboutCard/index";
import Navbar from "../../../component/navbar/index";
import AnimatedText from "react-animated-text-content";

const index = () => {
  return (
    <div id="About">
      <div className="about-media">
        <header>
          <div className="outter">
            <div className="video-container">
              <video src={Video} autoPlay loop playsInline muted />

              <div className="container-fluid px-4 rlr-callout">
                <Navbar />

                <div className="container">
                  <div className="row">
                    <div className="col-12  mt-5 aboutheading ">
                      <AnimatedText
                        type="words" // animate words or chars
                        animation={{
                          x: "200px",
                          y: "-20px",
                          scale: 1.1,
                          ease: "ease-in-out",
                        }}
                        animationType="throw"
                        interval={0.2}
                        duration={0.5}
                        tag="h1"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="0%"
                      >
                        Watch This Space Agency
                      </AnimatedText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default index;
