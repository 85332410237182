import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// import { Line1 } from "../component/extraInfo";
// import { Lin2 } from '../component/extraInfo';
import Header from "../component/header/index";
import About from "../component/about/index";
import Footer from "../component/footer/index";
import Service from "../component/services/index";
import Gallery from "../component/gallery/index";
import AboutHeader from "../pages/aboutPage/aboutHeader/index";
// import OurClient from "../component/ourClient/index";
// import AboutCard from "../pages/aboutPage/AboutCard/index";
import Filter from "../pages/servicePage/serviveHeader/filter";
import WorkHeader from "../pages/workPage/workHeader/index";
import WorkComponent1 from "../pages/workPage/workComponent1/index";
import ContactHeader from "../pages/contactPage/contactHeader/index";
import ServiceHeader from "../pages/servicePage/serviveHeader/index";
import Cards from "../component/cards/index";
import PropagateLoader from "react-spinners/PropagateLoader";

import Offices from "../component/ofiices/index";

// const Header = React.lazy(() => import("../component/header/index"));
const LazyLoader = React.lazy(() => import("../home/index"));
const index = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <>
            <Suspense
              fallback={
                <div>
                  <PropagateLoader
                    color="#f67510"
                    size={30}
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                  />
                  <h1 className="text-center" style={{ marginTop: "-300px" }}>
                    XCL TECHNOLOGIES...
                  </h1>
                </div>
              }
            >
              <LazyLoader />
            </Suspense>
            {/* <Header />
            <Cards />
            <About />
            <Service />
            <Gallery />
            <Offices />
            <Footer /> */}
          </>
        }
      />

      <Route
        path="/about"
        element={
          <>
            <AboutHeader />
            <About />
            <Cards />
            {/* <Line1 /> */}
            {/* <AboutCard /> */}
            {/* <OurClient /> */}
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/work"
        element={
          <>
            <WorkHeader />
            <WorkComponent1 />
            {/* <Lin2 /> */}
            <Footer />
          </>
        }
      />

      <Route
        exact
        path="/contact"
        element={
          <>
            <ContactHeader />
            <Footer />
          </>
        }
      />
      <Route
        exact
        path="/services"
        element={
          <>
            <ServiceHeader />
            {/* <Footer /> */}
          </>
        }
      />

      <Route
        exact
        path="/filter/:id"
        element={
          <>
            <Filter />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default index;
