import React from "react";
import Navbar from "../../../component/navbar/index";
import { useParams } from "react-router-dom";
import { filterData } from "../../../Data/api";
const Filter = () => {
  const params = useParams();
  const { id } = params;

  return (
    <div>
      <Navbar />

      <div className="container">
        <div className="row filter">
          {filterData
            .filter((index) => index.id == id)
            .map((item, index) => {
              return (
                <>
                  <div className="col-lg-6 filter_data">
                    <h1>{item.heading}</h1>
                    <p> {item.content}</p>
                  </div>
                  <div className="col-lg-6">
                    <img src={item.img} alt="product-img" width={"100%"} />
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Filter;
