import React, { useRef } from "react";
import "./about.css";
// import Mask from "../../Assets/Mask Group 2.png";
import Mask from "../../Assets/about-img.png";
import { useNavigate } from "react-router-dom";
import AnimatedText from "react-animated-text-content";

const About = () => {
  const reference = useRef(null);
  const navigate = useNavigate();
  const routing = () => {
    navigate("/about", { replace: true });
  };
  return (
    <div ref={reference} className="About bg-img" id="About">
      <div className="container about-con">
        <div className="row ">
          <AnimatedText
            type="words" // animate words or chars
            animation={{
              x: "200px",
              y: "-20px",
              scale: 1.1,
              ease: "ease-in-out",
            }}
            animationType="float"
            interval={0.06}
            duration={1.0}
            tag="h1"
            className="animated-paragraph text-center mt-2 about-heading"
            includeWhiteSpaces
            threshold={1.0}
            rootMargin="20%"
          >
            ABOUT US
          </AnimatedText>
        </div>

        <div className="row about-flex">
          <div className="col-lg-6  about-content">
            XCL Technologies Is A Leading Software Development, Web Development,
            Mobile App Development, Digital Marketing Including SEO Service
            Provider Company, Our company is ISO certified, which means we have
            met international standards for quality management and are committed
            to providing our clients with the highest level of service. We
            believe that our Innovative, Creative and skilled IT staff Is Our
            Company's Backbone.
            <br></br>
            <br></br>
            XCL Technologies Was Founded In Pakistan Having Marketing Offices In
            United Arab Emirates And Unites States Of America, To Provide
            Creative Web Design, Software Development, Web Development And
            Mobile App Development, Graphic Design And Digital Marketing
            Including SEO Service From Static Presentation Websites To Complex
            Database Driven With CMS, Ecommerce Websites Development.<br></br>
            <br></br>
            Among The Very Few In The Sector, We Have Also Stepped In
            Successfully To Introduce The Complete Business Automation Process
            That Has So Far Proved Its Worth.
            <br />
            {/* <Link to={"/about"}> */}
            <button
              onClick={() => {
                routing();
              }}
            >
              See More
            </button>
            {/* </Link> */}
          </div>
          <div className="col-lg-6  about-img">
            <img className="Mask" src={Mask} alt="" />
            <h3>Welcome.</h3>

            <p>
              To The New
              <span>World</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
