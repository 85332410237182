import React from 'react';
import './contactHeader.css';
import AnimatedText from 'react-animated-text-content';
import Video from '../../../Assets/contact-us.mp4';
// import Video from "../../../Assets/Banner Video.mp4";
// import WEB from "../../../Assets/WEB.png";
import Navbar from '../../../component/navbar2/index';
// import BackgroundIm from '../../../Assets/Group 135.png';
const index = () => {
  return (
    <div>
      <div className="contact-us">
        <header>
          <div className="outter">
            <div className="video-container">
              {/* <div className="contact-headerimg">
                <img src={BackgroundIm} alt="bg Img" />
              </div> */}
              <video src={Video} autoPlay loop playsInline muted />

              <div className="container-fluid px-4 rlr-callout">
                <Navbar />

                <div className="container" style={{ position: 'relative' }}>
                  <div className="row">
                    <div className="col-lg-12">
                      <AnimatedText
                        type="words" 
                        animation={{
                          x: '200px',
                          y: '-20px',
                          scale: 1.1,
                          ease: 'ease-in-out',
                        }}
                        animationType="float"
                        interval={0.06}
                        duration={1.0}
                        tag="h1"
                        className="animated-paragraph text-center contact-heading"
                        includeWhiteSpaces
                        threshold={1.0}
                        rootMargin="20%"
                      >
                        CONTACT US
                      </AnimatedText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* 2nd Component */}
      {/* <div className="container contactus-component">
        <div className="row">
          <div className="col-lg-6 left-side">
            <div className="pakistan">
              <h1>PAKISTAN</h1>
              <p>TEL: +92 22 6111876</p>
              <p>MOB: +92 332 3549993</p>
            </div>
            <div className="UAE">
              <h1>UAE</h1>
              <p>TEL: +971 06 5425139</p>
              <p>MOB: +971 55 2389772</p>
            </div>
            <div className="USA">
              <h1>USA</h1>
              <p>TEL: +1 443 6535673</p>
              <p>MOB: +1 443 6535914</p>
            </div>
          </div>
          <div className="col-lg-6 contact-right-side">
            <div>
              <h1>
                To ask a question, or just say "hello", contact us in any
                convenient way.
              </h1>
            </div>
            <div>
              <h4 className="text-center">ALAM ROAD, OPPOSITE, DEFENCE</h4>
              <h4 className="text-center">PARK, DEFENCE, HYDERABAD.</h4>
            </div>
            <div className="email">
              <h4 className="text-center" style={{ color: '#F67510' }}>
                GENERAL ENQUIRIES
              </h4>
              <h4 className="text-center">info@xcltechnologies.com</h4>
            </div>
          </div>
        </div>
      </div> */}
      {/* 3rd Component */}
      <div className="container section-2">
        <div className="row heading1">
          <h1 className="text-center ">
            LETS TALK ABOUT{' '}
            <h1 style={{ color: '#F67510', display: 'inline' }}>
              {' '}
              YOUR PROJECT
            </h1>{' '}
          </h1>
          <div className="col-lg-6"></div>
        </div>
        <div className="row input-fields">
          <div className="col-lg-6">
            {' '}
            <form>
              <div className="mb-3 mt-5">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Full Name*"
                  style={{
                    border: 'none',
                    borderBottom: '2px solid white',
                    background: 'transparent',
                  }}
                />
              </div>
              <div className="mb-3 mt-5">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email*"
                  style={{
                    border: 'none',
                    borderBottom: '2px solid white',
                    background: 'transparent',
                  }}
                />
              </div>
            </form>
          </div>
          <div className="col-lg-6">
            <div className="mb-3 mt-5">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Phone*"
                style={{
                  border: 'none',
                  borderBottom: '2px solid white',
                  background: 'transparent',
                }}
              />
            </div>
            <div className="mb-3 mt-5">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Company*"
                style={{
                  border: 'none',
                  borderBottom: '2px solid white',
                  background: 'transparent',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 4th Component */}
      <div className="container mt-5 ">
        <div className="row heading1">
          <h1 className="text-center ">
            WHICH OF{' '}
            <h1 style={{ color: '#F67510', display: 'inline' }}>
              {' '}
              OUR SERVICES
            </h1>{' '}
            WILL BE REQUIRED
          </h1>
          <h1 className="text-center ">
            FOR{' '}
            <h1 style={{ color: '#F67510', display: 'inline' }}>
              {' '}
              YOUR PROJECT
            </h1>{' '}
            ?
          </h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Software Development
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Web Design and Development
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                CMS and Ecommerce
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Mobile Applications
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Branding and Graphic Designs
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Digital Marketing
              </label>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Domain & Hosting
              </label>
            </div>
            <a href="/" style={{ color: '#F67510', textDecoration: 'none' }}>
              ATTACH FILE
            </a>
          </div>
          <div className="col-lg-6">
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Describe your project
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="9"
              ></textarea>
              <button type="submit" className="btn btn-primary submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
