import React from "react";
import "./serviceHeader.css";
import AnimatedText from "react-animated-text-content";
import Navbar from "../../../component/navbar2/index";
import Footer from "../../../component/footer/index";

import Networking from "../../../Assets/netwroking-service.png";
import HR from "../../../Assets/HUMAN R-01.png";
import Domain from "../../../Assets/DOMAIN_Монтажная область 1.png";
import Ecommerce from "../../../Assets/commerce.png";
import Digital from "../../../Assets/digital marketing.png";
import Banner from "../../../Assets/universe-view.jpg";

const Index = () => {
  return (
    <div>
      <div className="service-media">
        <header>
          <div className="outter">
            <div className="video-container">
              {/* <video src={Video} autoPlay loop playsInline muted /> */}
              <img className="banner-img" src={Banner} alt />

              <div className="container-fluid px-4 rlr-callout">
                <Navbar />

                <div className="container" style={{ position: "relative" }}>
                  <div className="row">
                    <div className="col-lg-12">
                      <AnimatedText
                        type="chars" // animate words or chars
                        animation={{
                          x: "200px",
                          y: "-20px",
                          scale: 1.1,
                          ease: "ease-in-out",
                        }}
                        animationType="bounce"
                        interval={0.1}
                        duration={0.8}
                        tag="h1"
                        className="animated-paragraph text-center contact-heading"
                        includeWhiteSpaces
                        threshold={1.0}
                        rootMargin="20%"
                      >
                        SERVICES
                      </AnimatedText>

                      <p className="service-sub-text">
                        We offer a wide array of services to cater to any of
                        your,<br></br> web, mobile or digital marketing
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Branding & graphics Design */}
      <div className="fluid service-component1">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Branding & Graphic Design</h3>
              <p>
                XCL TECHNOLOGIES specializes in creating unique, high-end web
                designs, print, and multimedia. Our talented staff is constantly
                striving to deliver products that exceed your expectations. Our
                goal is to make you stand out with style and be remembered.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our Services Include</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>UX Research</li>
                    <li>Information Architecture</li>
                    <li>Mood Boards</li>
                    <li>Wire framing</li>
                  </ul>
                </div>
                <div>
                  <li>Interaction Design</li>
                  <li>Information Architecture</li>
                  <li>UX Testing</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile App Development */}
      <div className="fluid service-component2">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Mobile App Development</h3>
              <p>
                XCL TECHNOLOGIES specializes in creating unique, high-end web
                designs, print, and multimedia. Our talented staff is constantly
                striving to deliver products that exceed your expectations. Our
                goal is to make you stand out with style and be remembered.
                Intuitive designs combined with compelling user experience are
                what makes our apps stand ahead in the digital age. Seamless
                experience driven by international standards in collaboration
                with out-of-the-box ideas is the specialty of XCL apps helping
                your business accomplish its goals.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our Mobile App Solutions</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Android App Development</li>
                    <li>React Native App Development</li>
                    <li>iPhone Development</li>
                    <li>iPad App Development</li>
                    <li>Apple Watch App Development</li>
                  </ul>
                </div>
                <div>
                  <li>Windows Mobile App Development</li>
                  <li>PhoneGap App Development</li>
                  <li>Xamarin App Development</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Web Design & development */}
      <div className="fluid service-component3">
        <div className="container mb-3">
          <div className="row ">
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Web Design & Development</h3>
              <p>
                XCL TECHNOLOGIES specializes in creating unique, high-end web
                designs, print, and multimedia. Our talented staff is constantly
                striving to deliver products that exceed your expectations. Our
                goal is to make you stand out with style and be remembered.
                Cross-browser and cross-device compatibility driven by mobile
                responsiveness all under one roof Starting from basic website
                designs, including CMS and online store building to highly
                complex business website apps and design solutions, we will
                customize the best of web development solutions for you.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our Web Development Services</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Custom Application Development</li>
                    <li>Content Management System (CMS)</li>
                  </ul>
                </div>
                <div>
                  <li>Ecommerce Development</li>
                  <li>MEAN Stack Development</li>
                  <li>Social Media Apps</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Networking & Survuilance */}
      <div className="fluid service-component4">
        <div className="container mb-3">
          <div className="row ">
            <div
              className="col-lg-6 col-sm-12 service-page-img"
              style={{ display: "flex", justifyContent: "start" }}
            >
              <img src={Networking} alt="" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Networking & Surveillance</h3>
              <p>
                At XCL we can help businesses design and implement a network and
                server infrastructure that meets their specific needs. This
                includes selecting hardware and software, configuring the
                network, and optimizing performance.We can also help businesses
                secure their network and server infrastructure by implementing
                security measures such as firewalls, intrusion detection and
                prevention systems, and access controls. They can also help
                organizations develop and implement security policies and
                procedures
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our Networking Services</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Network design and implementation</li>
                    <li>Network security</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Network monitoring and management</li>
                    <li>Cloud networking</li>
                    <li>Collaboration tools</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* software Development */}
      <div className="fluid service-component1 m-0">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Software Development</h3>
              <p>
                XCL Technologies has a team of Desktop application experts which
                loop on the latest in software technologies to convert your
                dreams of express service to your customers into reality. We
                help everyone who wants desktop or web applications and software
                development. It doesn’t matter if you have just a software idea,
                if it is a solid one, we can help you with cross-platform and
                platform-specific desktop application development and software
                development services.
              </p>
              <h4 style={{ color: "#6f7a82" }}>
                Our Software development services Include
              </h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Custom Software Development</li>
                    <li>Software Integration</li>
                  </ul>
                </div>
                <div>
                  <li>Software Testing</li>
                  <li> Quality Assurance</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Digital Marketing */}
      <div className="fluid service-component5">
        <div className="container mb-3">
          <div className="row ">
            <div
              className="col-lg-6 col-sm-12 service-page-img"
              style={{ display: "flex", justifyContent: "start" }}
            >
              <img style={{ width: "80%" }} src={Digital} alt="" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Digital Marketing</h3>
              <p>
                Welcome to our digital marketing services! We are a software
                company that specializes in providing top-notch digital
                marketing services to businesses of all sizes. We understand
                that in today's digital age, having a strong online presence is
                essential for businesses to succeed. That's why we offer a range
                of digital marketing services to help your business reach its
                target audience and achieve its marketing goals
              </p>
              <h4 style={{ color: "#6f7a82" }}>
                Our Digital Marketing Services
              </h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Search Engine Optimization (SEO)</li>
                    <li>Pay-per-click Advertising (PPC)</li>
                    <li>Social Media Marketing</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Email Marketing</li>
                    <li>Content Marketing </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Human Resources */}
      <div className="fluid service-component6">
        <div className="container mb-3">
          <div className="row ">
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Human Resources</h3>
              <p>
                Welcome to our human resources services! As a software company,
                we understand the importance of having an effective and
                efficient human resources department. That's why we offer a
                range of human resources services to help your business
                streamline its HR processes and improve its overall performance.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our HR Services:</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Employee Performance Management</li>
                    <li>Payroll Processing</li>
                    <li>Benefits Administration</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Recruitment and Hiring</li>
                    <li>HR Compliance</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-12 service-page-img"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <img width={"80%"} src={HR} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* CMS & E-commerce */}
      <div className="fluid service-component7">
        <div className="container mb-3">
          <div className="row ">
            <div
              className="col-lg-6 col-sm-12 service-page-img"
              style={{ display: "flex", justifyContent: "start" }}
            >
              <img width={"80%"} src={Ecommerce} alt="" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">CMS & E-Commerce</h3>
              <p>
                Welcome to our e-commerce services! As a software company, we
                understand the importance of having a strong online presence for
                businesses to succeed. That's why we offer a range of e-commerce
                services to help your business succeed in the competitive world
                of online sales.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our CMS Services</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Inventory Management </li>
                    <li>Payment Gateway Integration</li>
                    <li>Order Fulfillment </li>
                    <li>Marketing and Promotions </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* domain & Web Hosting */}
      <div className="fluid service-component8">
        <div className="container mb-3">
          <div className="row ">
            <div className="col-lg-6 col-sm-12">
              <h3 className="ux-design">Domain & Web Hosting</h3>
              <p>
                In today's digital age, having a strong online presence is
                crucial for any business or individual. That's where our Domain
                and Web Hosting services come in. We provide a reliable and
                secure platform for you to establish and maintain your online
                identity.
              </p>
              <h4 style={{ color: "#6f7a82" }}>Our Domain Services Include:</h4>
              <div className="ui-sublist">
                <div>
                  <ul>
                    <li>Domain name registration</li>
                    <li>Web hosting services</li>
                    <li>Scalable storage and bandwidth options</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      Technical support for troubleshooting and assistance
                    </li>
                    <li>Customizable hosting plans to fit specific needs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-12 service-page-img"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <img src={Domain} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
